import React, { useEffect } from 'react';
import Amplify, { Auth } from 'aws-amplify';
import { Router } from '@reach/router';
import * as styles from '../styles/CourseCertificate.module.scss';
import Footer from '../components/Shared/Footer';
import Header from '../components/Shared/Header';

import awsconfig from '../backendConfigProvider';
import PublicCertificate from '../components/PublicCertificate';
import { viewedPage } from '../services/TrackActionService';
import { PageKind } from '../components/Learner/Services/LearnerNavigations';
import changeSync from '../services/DataStoreConfigure';

Amplify.configure(awsconfig);

/**
 *
 *
 * @return {*}
 */
const CourseCertificate: React.FC = () => {
  useEffect(() => {
    (async () => {
      const currentAuthUser = await Auth.currentAuthenticatedUser();
      const sub = currentAuthUser?.attributes?.sub;
      await changeSync(sub);
    })();

    viewedPage({
      pageKind: PageKind.PUBLIC_COURSE_CERTIFICATE,
    });
  }, []);
  return (
    <div className={styles.pageWrapper}>
      <Header />
      <div className={styles.certificateWrapper}>
        <Router basepath="/course-certificate">
          <PublicCertificate
            path="/:id"
          />
          <PublicCertificate
            default
          />
        </Router>
      </div>
      <Footer />
    </div>
  );
};

export default CourseCertificate;
