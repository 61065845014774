import React, { useEffect, useState } from 'react';
import { navigate } from 'gatsby';
import { Storage } from 'aws-amplify';
import * as styles from '../styles/PublicCertificate.module.scss';
import CertificateViewer from './Learner/CertificateViewer';

type Props = {
  id?: string,
  path?: string,
  default?: boolean,
}
/**
 *
 *
 * @return {*}
 */
const PublicCertificate: React.FC<Props> = ({ id }) => {
  const [certificate, setCertificate] = useState<string | null>(null);

  /**
   *
   * fetches the certificate from s3
   */
  const fetchCertificate = async () => {
    const certificateRes = await Storage.get(`certficates/${id}/certificate`, { level: 'public' }) as string;
    setCertificate(certificateRes);
  };

  useEffect(() => {
    if (!id) {
      navigate('/');
    } else {
      fetchCertificate();
    }
  }, []);
  return (
    <div className={styles.certificateContainer}>
      {certificate && <CertificateViewer certificate={certificate} />}
    </div>
  );
};
export default PublicCertificate;
