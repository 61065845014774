import * as React from 'react';
import { Storage } from 'aws-amplify';
import * as styles from '../../styles/Header.module.scss';
import { downloadBlob } from '../Learner/Services/CourseRegistrationService';

const Header: React.FC = () => {
  const downloadCertificate = async () => {
    const id = window.location.pathname.split('/')[2];
    try {
      const fileKey = `certficates/${id}/certificate`;
      const result = await Storage.get(fileKey, { download: true, level: 'public' }) as any;
      await downloadBlob(result.Body, 'certificate');
    } catch (err) {
      console.error(err);
    }
  };
  return (
    <header className={styles.header}>
      <div>
        <div className={styles.heading}>
          <div className={styles.rightBorder} />
          <h1>FOR ENTERPRISE</h1>
        </div>
        <div>
          <button
            type="button"
            id="course-certificate-download-now"
            className={styles.downloadCta}
            onClick={downloadCertificate}
          >
            <span className="library-shaw-icon-new">
              <i className="icon-new-Download1" />
            </span>
            <p>Download now!</p>
          </button>
        </div>
      </div>
    </header>
  );
};

export default Header;
